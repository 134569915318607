<template>
  <Popup ref="popupRef" width="500px" title="充值会员" @confirm="confirm" @close="resetData">
    <el-form label-position="top">
      <el-form-item label="会员等级">
        <el-radio-group v-model="form.vipLevel">
          <el-radio :label="item.type" v-for="(item, index) in vipTypes" :key="index">{{item.text}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="充值时长">
        <el-radio-group v-model="form.type">
          <el-radio :label="item.type" v-for="(item, index) in durationTypes" :key="index">{{item.text}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- <div class="label">
      <span>会员等级</span>
    </div>
    <el-radio-group v-model="form.vipLevel">
      <el-radio :label="item.type" v-for="(item, index) in vipTypes" :key="index">{{item.text}}</el-radio>
    </el-radio-group>
    <div class="label">
      <span>充值时长</span>
    </div>
    <el-radio-group v-model="form.type">
      <el-radio :label="item.type" v-for="(item, index) in durationTypes" :key="index">{{item.text}}</el-radio>
    </el-radio-group> -->
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { rechargeMemberApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      vipTypes: [
        {text:'青铜会员',type:1},
        {text:'白银会员',type:2},
        {text:'黄金会员',type:3},
      ],
      durationTypes: [
        {text:'月度',type:1},
        {text:'季度',type:2},
        {text:'年度',type:3},
      ],
      form: {
        type: 1,
        vipLevel: 1 
      }
    }
  },
  methods: {
    open(option = {}) {
      this.mid = option.mid || ''
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.mid = ''
      this.form = {
        type: 1,
        vipLevel: 1 
      }
    },
    confirm() {
      const formData = {
        mid: this.mid,
        type: this.form.type,
        vipLevel: this.form.vipLevel
      }
      console.log('formData', formData)
      rechargeMemberApi(formData).then(res => {
        console.log('充值结果', res)
        this.$tips({message: res.msg, type:'success'})
        this.close()
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0 8px;
  font-size: 14px;
  color: #505E5C;
}
</style>